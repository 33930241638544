<!-- 招生系统基础配置 -- 中考考试科目管理 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(()=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<el-form ref="form" :model="form" label-width="150rem" style="width: calc(100% - 24rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="报名学校">
        {{ form.school }}
      </el-form-item>
			<div style="display: flex; justify-content: left">
				<el-form-item label="考试区域">
					{{ form.area_text }}
				</el-form-item>
			</div>
      <el-form-item label="档位名称">
        {{ form.rank_name }}
      </el-form-item>
			<el-form-item label="考试年份">
				{{ form.year }}
			</el-form-item>
			<!--			<el-form-item label="优惠商品">-->
			<!--				{{ form.goods }}-->
			<!--			</el-form-item>-->
			<el-form-item label="用户分类" prop="type">
				{{form.type == 1 ? '正式报名结尾款用户' : form.type == 2 ? '未参加预报名直接报名用户' : ''}}
			</el-form-item>

			<h4 style="padding: 50rem 0 20rem 30rem">活动优惠</h4>

			<div v-for="(item, index) in form.amount" :key="index" class="knowledge">
				<div class="flex" style="background: #f4f4f4;padding: 20rem">
					<el-form-item label="开始时间" label-width="80rem" style="margin-bottom: 0">
						<el-date-picker v-model="item.start_at" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
					</el-form-item>
					<el-form-item label="结束时间" label-width="80rem" style="margin-bottom: 0">
						<el-date-picker v-model="item.end_at" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
					</el-form-item>
					<el-form-item label="优惠金额" label-width="80rem" style="margin-bottom: 0">
						<el-input-number v-model="item.amount" :max="1000000000" :min="0" :precision="2" :step="100" style="display: flex;flex: 1"></el-input-number>
					</el-form-item>
				</div>
				<div class="flex" style="margin-left: 40rem">
					<el-button v-if="form.amount.length !== 1" circle="" icon="el-icon-delete" @click="deleteKnowledge(index)"></el-button>
					<el-button circle="" icon="el-icon-plus" @click="addKnowledge"></el-button>
				</div>
			</div>
		</el-form>
		
	
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
		return {
			backType: 0,
			form: {
				province: '',
				city: '',
				area: '',
				school_id: '',
				year: '',
				goods_id: '',
				examination_rank_id: '',
				amount: [{start_at: '', end_at: '', amount: ''}],
			},
			provinceOptions: [],
			cityOptions: [],
			areaOptions: [],
			subjectOptions: [],
			schoolOptions: [],
			goodsOptions: [],
			examinationOptions: [],
		}
	},
	created() {
		this.getProvince()
		this.getSubject()
		// 获取详情
		this.$_register('/api/recruit/scholarship/show', {params: {id: this.$route.query.id}}).then(res => {
			this.form = res.data.data
		})
	},
	methods: {
		getSubject() {
			this.$_register('/api/recruit/examination/subject').then(res => {
				let data = res.data.data
				if (data.length) {
					this.subjectOptions = data.map(item => {
						return {
							...item,
							disabled: false
						}
					})
				}
			})
		},

		// 添加科目
		addKnowledge() {
			this.form.amount.push({start_at: '', end_at: '', amount: ''})
		},

		//获取省份
		getProvince() {
			this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
				// console.log(res)
				this.provinceOptions = res.data.data
			})
		},

		//获取城市
		getCity(e) {
			this.cityOptions = []
			this.form.city = ''
			this.areaOptions = []
			this.form.area = ''
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.cityOptions = res.data.data
			})
		},

		//获取区/县
		getArea(e) {
			this.areaOptions = []
			this.form.area = ''
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.areaOptions = res.data.data
			})
		},

		// 省份变更
		onProvinceChange(val) {
			this.getCity(val)
		},

		// 城市变更
		onCityChange(val) {
			this.getArea(val)
		},

		// 删除科目
		deleteKnowledge(index) {
			this.form.amount.splice(index, 1)
		},

		// 提交数据
		submit() {
			this.$refs.form.validate((res) => {
				if (res) {
					let params = {...this.form}
					this.$_register.post("api/recruit/scholarship/update", {id: this.$route.query.id, amount: params.amount}).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg,
							onClose: () => {
								this.$store.commit("setPage", 1);
								this.backType = 1
								this.$router.back();
							}
						});
					})
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>

.form {
	padding-top: 24rem;
}

::v-deep .el-form .el-input {
	min-width: 250rem;
}

.knowledge {
	margin-top: 24rem;
	display: flex;
	align-items: center;

	.knowledge_box {
		display: flex;
		padding-top: 12rem;
		height: 60rem;
		background-color: #f4f4f4;
	}

	.add_knowledge {
		display: flex;
	}
}
.el-input-number {
	min-width: 250rem;
}
</style>
